import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import windowsIcon from "../icons/windows.svg"
import linuxIcon from "../icons/linux.svg"
import macIcon from "../icons/mac.svg"
import arrowIcon from "../icons/arrow.svg"
// utils
import collapseList from '../utils/collapseList';

const IndexPage = () => {
    useEffect(() => {
        // Hero Animated Search
        //Search functionality
        const searchList = document.querySelector('.search-result');
        const searchInput = document.querySelector('.hero-preview-animation input');
        const activeIcon = document.querySelector('#active-icon');
        let keydown;

        const listOfItems = [
            {
                icon: "💡",
                title: "Bedroom Light (ON/OFF)",
                id: "1"
            },
            {
                icon: "💡",
                title: "Bedroom Light (Movie Mode)",
                id: "2"
            },
            {
                icon: "💡",
                title: "Bedroom Light (Sleep Mode)",
                id: "3"
            },
            {
                icon: "💡",
                title: "Bedroom Light (Blue)",
                _id: "4"
            },
            {
                icon: "💡",
                title: "Bedroom Light (Green)",
                _id: "5"
            },
            {
                icon: "🧿",
                title: "Living Room LED (ON/OFF)",
                _id: "6"
            },
            {
                icon: "🧿",
                title: "Living Room LED (Green)",
                _id: "7"
            },
            {
                icon: "🧿",
                title: "Living Room LED (Red)",
                _id: "8"
            },
            {
                icon: "🧹",
                title: "Vacuum Robot (Start)",
                _id: "9"
            },
            {
                icon: "🧹",
                title: "Vacuum Robot (Stop)",
                _id: "10"
            },
            {
                icon: "🧹",
                title: "Vacuum Robot (Pause)",
                _id: "11"
            },
            {
                icon: "🧹",
                title: "Vacuum Robot (Resume)",
                _id: "12"
            },
            {
                icon: "🚪",
                title: "Front Door Lock (Open)",
                _id: "13"
            },
            {
                icon: "🚪",
                title: "Front Door Lock (Close)",
                _id: "14"
            },
            {
                icon: "📍",
                title: "Find My Phone (Ring)",
                _id: "15"
            },
            {
                icon: "☕",
                title: "Kitchen Kettle (ON)",
                _id: "16"
            },
            {
                icon: "☕",
                title: "Kitchen Kettle (OFF)",
                _id: "17"
            },
            {
                icon: "💡",
                title: "Kitchen Light (ON/OFF)",
                _id: "18"
            },
            {
                icon: "💡",
                title: "Kitchen Light (Dim 50%)",
                _id: "19"
            }
        ]

        searchInput.addEventListener('input', e => {
            const input = e.target.value.toLowerCase().trim();
            const filteredItems = listOfItems.filter(item => item.title.toLowerCase().includes(input))
            // sort items alphabetically
            let result = filteredItems.sort((a, b) => {
                if(a.title > b.title) {
                    return 1;
                } else {
                    return -1;
                }
            });

            //check if the input is empty
            if(input.length === 0) {
                //clearing <ul> (searchList)
                searchList.innerHTML = ``;
                // set result window size
                // ipcRenderer.invoke('search:resize', 0);
                // remove active event's icon from search input field
                activeIcon.innerHTML = ``;
            } else {
                //clearing <ul> (searchList) everytime there is a new response from ipcMain.handle
                searchList.innerHTML = ``;
                // check if there are any match, if not - return 'Not Found' message
                if(result.length === 0) {
                    // set result window size to fit 'Not Found message'
                    // ipcRenderer.invoke('search:resize', 1);
                    searchList.innerHTML = `<li>
                            <div name="icon" data-icon="❌" class="item event-icon">❌</div><div class="search-event-title">Nothing found...</div>
                        </li>`;
                    // setup active icon
                    activeIcon.innerHTML = searchList.firstChild.children[0].dataset.icon;
                    return;
                }
                //going through response(result) and outputing matched results
                // maximum items to output = 4
                for(let i = 0; i < result.length && i < 4; i++) {
                    // set result window size to fit all received items
                    // ipcRenderer.invoke('search:resize', i + 1);
                    const html = `
                        <li data-id="${result[i].id}" data-icon="${result[i].icon ? result[i].icon : ''}">
                            <div name="icon" class="item event-icon">${result[i].icon ? result[i].icon : ''}</div><div class="search-event-title">${result[i].title}</div>
                        </li>
                    `;
                    //appending results to the <ul> (searchList)
                    searchList.innerHTML += html;
                }
                //adding active class to the very first event in the result list
                //checking if any <li> present in the result
                if(searchList.children[0]) {
                    // make active & hightlight first item in the list
                    searchList.children[0].classList.add('active-event');
                    // set icon of selected element in the list to search input field
                    activeIcon.innerHTML = searchList.children[0].dataset.icon;
                }
            };
        });

        // Typing imitation feature
        searchInput.focus()

        const words = ['light', 'vacuum', 'kitchen', 'door'];
        const time = 100;

        let i = 0;
        let w = 0;
        const write_text = (word) => {
            searchInput.value += word[i];
            searchInput.dispatchEvent(new Event('input', { bubbles: true }))
            
            if(i < word.length - 1) {
                i++;
                setTimeout(function(){write_text(words[w])}, time);
            } else {
                w++;
                i = 0;
                if(w <= words.length - 1) {
                    setTimeout(function(){
                        searchInput.value = "";
                        write_text(words[w])
                    }, 3000);
                } else {
                    w = 0;
                    setTimeout(function(){
                        searchInput.value = "";
                        write_text(words[w])
                    }, 3000);
                }
            }
        }

        write_text(words[w])
        
        // FAQ Collapse
        collapseList()
    }, [])

    return (
        <Layout>
            <SEO title="Toggol — All Your Smart Devices Within Single App On Desktop" description="Toggol app allows you to access all your smart home devices within single app on your desktop. Just one click of a button and your bedroom light is on!" />
            <section className="hero homepage-hero">
                <h1>All Your Smart Devices</h1>
                <div className="hero-description">Within Single App On Desktop</div>
                <div className="hero-preview-animation">
                    <div className="search-event-window">
                        <div className="toggol-search">
                            <div className="search-icon icon"></div><input disabled type="text" placeholder="Toggol Search" /><div id="active-icon"></div>
                        </div>
                        <div className="search-dropdown">
                            <ul className="search-result">
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="homepage-body">
                <div id="download" className="container-download container anchor">
                    <h2 className="section-title">Download</h2>
                    <ul className="download-buttons inline-list">
                        <li>
                            <Link to="/download#windows" className="btn">
                                <div>Windows</div>
                                <div className="container-icon"><img className="download-icon icon" src={windowsIcon} alt="windows icon" /></div>
                            </Link>   
                        </li>
                        <li>
                            <Link to="/download#mac" className="btn">
                                <div>MacOS</div>
                                <div className="container-icon container-btn-mac"><img className="download-icon icon mac-os-icon" src={macIcon} alt="macos icon" /></div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/download#linux" className="btn">
                                <div>Linux</div>
                                <div className="container-icon"><img className="download-icon icon" src={linuxIcon} alt="linux icon" /></div>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div id="faq" className="container-faq container">
                    <div className="faq-content">
                        <h2 className="section-title">FAQ</h2>
                        <div className="collapse-table no-click-highlight">
                                <ul className="mobile-list noselect">
                                    <li>
                                        <div><div className="collapse-title">Which devices can I access with Toggol?</div>
                                        <div className="collapse-content hidden">Literally any smart device which you're able to connect to IFTTT, Zapier or other platform. You can check all the services they have available here: <a className="link text-underline" href="https://ifttt.com/services">IFTTT</a>, <a className="link text-underline" href="https://zapier.com/apps">Zapier</a>.</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                        </li>
                                    <li>
                                        <div><div className="collapse-title">Is it free?</div>
                                        <div className="collapse-content hidden">Yes. Toggol is currently free for use for everyone! I'd appreciate your feedback about the product as a gratitude though.</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                    <li>
                                        <div><div className="collapse-title">How to open device search window?</div>
                                        <div className="collapse-content hidden">The default shortcut for opening device search is Alt/Option+Space, but you can change the shortcut in app settings. Learn more here: <Link className="link text-underline" to="/how-to-use#how-to-shortcut">how to change the shortcut</Link>.</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                    <li>
                                        <div><div className="collapse-title">How to add new device?</div>
                                        <div className="collapse-content hidden">The process is really simple, to trigger your smart device the only thing you need - an endpoint to hit. Learn more about how to obtain one here: <Link className="link text-underline" to="/how-to-use#how-to-endpoint">how to create an endpoint</Link>.</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                    <li>
                                        <div><div className="collapse-title">I forgot my password, how to restore?</div>
                                        <div className="collapse-content hidden">If you forgot your password, feel free to contact <a className="link text-underline" href="mailto:support@toggol.com">support@toggol.com</a> and I'll be happy to assist you.</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                </ul>
                                <ul className="mobile-list noselect">
                                    <li>
                                        <div><div className="collapse-title">Do I need Alexa or Google Home for this?</div>
                                        <div className="collapse-content hidden">Nope! Voice assistants like Alexa or Google Home are completely optional and have nothing to do with Toggol App {`(`}even though you can trigger them with Toggol too!{`)`}.</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                    <li>
                                        <div><div className="collapse-title">How to customize the shortcut?</div>
                                        <div className="collapse-content hidden">You can customize the shortcut in app settings, which you can open from the main app screen. Learn more how to edit shortcut here: <Link className="link text-underline" to="/how-to-use#how-to-shortcut">how to customize the shortcut</Link>.</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                    <li>
                                        <div><div className="collapse-title">How to update the app?</div>
                                        <div className="collapse-content hidden">Currently, the only way to update the app is to reinstall it. If there is a new update available, you can uninstall your app and install the latest version. I'll, definitely, add an auto-update feature in future updates!</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                    <li>
                                        <div><div className="collapse-title">How to install?</div>
                                        <div className="collapse-content hidden">The process is very similar for all other apps, nothing really special. Learn more here: <Link className="link text-underline" to="/how-to-use#how-to-install">how to install toggol app</Link>.</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                    <li>
                                        <div><div className="collapse-title">App is not from signed developer?</div>
                                        <div className="collapse-content hidden">Signing an app costs money, not that big but still! First, I wanted to see if people like my app and would use it, before spending any budget on it. I'll, definitely, sign the app in future updates! Learn how to install and open/allow non-signed app: <Link className="link text-underline" to="/how-to-use#how-to-install">how to install toggol app</Link>.</div>
                                        </div>
                                        <div className="container-collapse-icon"><img src={arrowIcon} alt="arrow-icon" className="collapse-arrow-icon" /></div>
                                    </li>
                                </ul>
                        </div>
                        <div className="text-center my-5 faq-download-btn">
                            <Link to="/download" className="btn">Download Toggol</Link>
                        </div>
                    </div>
                    <svg className="faq-waves" id="shape-faq" x="0px" y="0px" viewBox="0 0 1600 1156" preserveAspectRatio="none" fill="none" fillOpacity="1" xmlns="http://www.w3.org/2000/svg">
                        <path className="wave-1" fill="#272729">
                            <animate repeatCount="indefinite" fill="freeze" attributeName="d" dur="10s" values="M 0 100 Q 100 0 200 100 Q 300 200 400 100 Q 500 0 600 100 Q 700 200 800 100 Q 900 0 1000 100 Q 1100 200 1200 100 Q 1300 0 1400 100 Q 1500 200 1600 100 L 1600 950 L 0 1150 Z;M 0 100 Q 100 200 200 100 Q 300 0 400 100 Q 500 200 600 100 Q 700 0 800 100 Q 900 200 1000 100 Q 1100 0 1200 100 Q 1300 200 1400 100 Q 1500 0 1600 100 L 1600 950 L 0 1150 Z;M 0 100 Q 100 0 200 100 Q 300 200 400 100 Q 500 0 600 100 Q 700 200 800 100 Q 900 0 1000 100 Q 1100 200 1200 100 Q 1300 0 1400 100 Q 1500 200 1600 100 L 1600 950 L 0 1150 Z;M 0 100 Q 100 200 200 100 Q 300 0 400 100 Q 500 200 600 100 Q 700 0 800 100 Q 900 200 1000 100 Q 1100 0 1200 100 Q 1300 200 1400 100 Q 1500 0 1600 100 L 1600 950 L 0 1150 Z;M 0 100 Q 100 0 200 100 Q 300 200 400 100 Q 500 0 600 100 Q 700 200 800 100 Q 900 0 1000 100 Q 1100 200 1200 100 Q 1300 0 1400 100 Q 1500 200 1600 100 L 1600 950 L 0 1150 Z"></animate>
                        </path>
                    </svg>
                </div>
                <div className="container-video text-center">
                    <iframe className="homepage-video-promo" width="370" height="200" src="https://www.youtube.com/embed/IvZX5nfLVB8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="container-learn-more text-center container">
                    <h2 className="section-title">Learn More</h2>
                    <p className="w-35 mx-auto">Still not sure how it works? Visit this page to learn more about the app and how to use it.</p>
                    <div className="my-5">
                        <Link to="/how-to-use" className="btn">Learn more</Link>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default IndexPage
