/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import MadeBy from "./MadeBy"
import "./normalize.css"
import "./main.css"

const Layout = ({ children }) => {
  return (
    <div className="container-main">
      <Header />
      {children}
      <Footer />
      <MadeBy />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout